window.mapping = function (latitude = null, longitude = null) {
    return {
        latitude: latitude,
        longitude: longitude,
        address: '',
        address_1: '',
        address_2: '',
        suburb: '',
        state: '',
        postcode: '',
        apiKey: window.maps_api_key,
        doingLookup: false,
        draggable: true,
        init() {
            /*const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;
            console.log(scriptUrl)
            if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
                let script = document.createElement('script');
                script.src = scriptUrl;
                script.async = true;
                script.defer = true;
                script.onload = () => this.initAutocomplete();
                document.head.appendChild(script);
            } else {*/
                this.initAutocomplete();
            //}
        },
        getUserGPS() {
            this.doingLookup = true;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.latitude = position.coords.latitude;
                    this.longitude = position.coords.longitude;
                    console.log(this.latitude, this.longitude)
                    this.getAddress();
                });
            } else {
                console.log("Geolocation is not supported by this browser.");
                this.doingLookup = false;
            }
        },
        // get address from coordinates
        async getAddress() {
            const lat = this.latitude;
            const lng = this.longitude;
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apiKey}`;

            try {
                const response = await fetch(url);
                const data = await response.json();
                console.log(data)
                if (data.status === 'OK' && data.results.length > 0) {
                    this.address = data.results[0].formatted_address;
                    console.log(this.address)
                } else {
                    this.address = 'Address not found';
                }
                this.doingLookup = false;
            } catch (error) {
                console.error('Error fetching address:', error);
                this.address = 'Error fetching address';
                this.doingLookup = false;
            }
        },
        // get coordinates from address
        async getCoordinates(address) {
            const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${this.apiKey}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                if (data.status === 'OK' && data.results.length > 0) {
                    const coordinates = data.results[0].geometry.location;
                    this.latitude = coordinates.lat;
                    this.longitude = coordinates.lng;

                    this.moveMapMarker();
                } else {
                    this.address = 'Address not found';
                }
                this.doingLookup = false;
            } catch (error) {
                console.error('Error fetching address:', error);
                this.address = 'Error fetching address';
                this.doingLookup = false;
            }
        },
        moveMapMarker() {
            if (typeof window.map === 'undefined') {
                return
            }

            const lat = this.latitude;
            const lng = this.longitude;
            // New leftlet lat long
            const latLng = L.latLng(lat, lng);

            // Center the leflet map
            window.map.setView(latLng, 15);
            window.mapMarker.setLatLng(latLng);

            document.getElementById('listing_gps_lat').value = lat;
            document.getElementById('listing_gps_lng').value = lng;

            //window.marker.setPosition(latLng);
        },
        initAutocomplete() {
            if (typeof google === 'undefined' || typeof google.maps === 'undefined' || typeof google.maps.places === 'undefined' || google.maps.places.Autocomplete === 'undefined') {
                setTimeout(() => this.initAutocomplete(), 500);
                console.log('Google Maps API not loaded yet');
                return;
            }

            if (typeof this.$refs.autocomplete === 'undefined' || this.$refs.autocomplete === null) {
                return;
            }

            this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, {
                types: ['geocode'],
                componentRestrictions: { country: 'AU' }
            });
            this.autocomplete.addListener('place_changed', () => {
                const place = this.autocomplete.getPlace();
                if (!place.geometry) {
                    console.error("Returned place contains no geometry");
                    return;
                }

                this.address = place.formatted_address;

                // Initialize the address components
                this.address_1 = '';
                this.address_2 = '';
                this.suburb = '';
                this.state = '';
                this.postcode = '';

                // Parse each component of the address
                place.address_components.forEach((component) => {
                    const types = component.types;
                    if (types.includes('street_number')) {
                        this.address_1 = component.long_name;
                    }
                    if (types.includes('route')) {
                        this.address_1 += (this.address_1.length ? ' ' : '') + component.long_name;
                    }
                    if (types.includes('locality')) {
                        this.suburb = component.long_name;
                    }
                    if (types.includes('administrative_area_level_1')) {
                        this.state = component.short_name;
                    }
                    if (types.includes('postal_code')) {
                        this.postcode = component.long_name;
                    }
                });

                this.latitude = place.geometry.location.lat();
                this.longitude = place.geometry.location.lng();

                this.moveMapMarker();
            });
        }
    }
}